import { Link } from 'react-router-dom';

const Home = () => {
    const battArray = [
        {
            name: "501st Legion",
            id: 1,
            img: '/img/501st.jpg'
        },
        {
            name: "212th Attack Battalion",
            id: 2,
            img: '/img/212th.jpg'
        },
        {
            name: "Galactic Marines",
            id: 3,
            img: '/img/gm.jpg'
        },
        {
            name: "Coruscant Guard",
            id: 4,
            img: '/img/cg.jpg'
        },
        {
            name: "104th Battalion",
            id: 5,
            img: '/img/104th.jpg'
        },
        {
            name: "Rancor",
            id: 6,
            img: '/img/rancor.jpg'
        },
        {
            name: "Doom's Unit",
            id: 7,
            img: '/img/du.jpg'
        },
        {
            name: "Republic Commandos",
            id: 8,
            img: '/img/rc.jpg'
        },
        {
            name: "41st Eilte Corps",
            id: 9,
            img: '/img/41st.jpg'
        },
        ]

    return ( 
        <div className="home">
            <div className="battalion-grid">
                {battArray.map((batt, index) => (
                    <Link 
                      key={index}
                      to={`/roster/${batt.name}`}
                      className="battalion-button"
                      style={{
                        backgroundImage: `url(${batt.img})`
                      }}
                    >
                      <span>{batt.name}</span>
                    </Link>
                ))}
            </div>
        </div>
    );
}
 
export default Home;
