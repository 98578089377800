import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const Roster = ({ match }) => {
    const synergy = {
        ranks: {
          Ranks: {
            Clone: {
              1: {
                Name: "Recruit",
                Prefix: "RCT",
                Weight: 1,
                CanPromote: false
              },
              2: {
                Name: "PVT",
                Weight: 2,
                CanPromote: false
              },
              3: {
                Name: "SPC",
                Weight: 3,
                CanPromote: false
              },
              4: {
                Name: "CPL",
                Weight: 4,
                CanPromote: false
              },
              // NCO
              5: {
                Name: "SGT",
                Weight: 5,
                CanPromote: false
              },
              6: {
                Name: "SFC",
                Weight: 6,
                CanPromote: false
              },
              7: {
                Name: "SGM",
                Weight: 7,
                CanPromote: false
              },  
              // OFFICER 
              8: {
                Name: "WO",
                Weight: 8,
                BattalionLocked: true,
                CanPromote: {
                  PVT: true,
                  PFC: true,
                  SPC: true,
                  CPL: true,
                  SGT: true,
                  SSG: true,
                  SFC: true,
                  MSG: true,
                  "1SG": true,
                  SGM: true,
                  CSM: true,
                }
              },
              9: {
                Name: "LT",
                Weight: 9,
                BattalionLocked: true,
                CanPromote: {
                  PVT: true,
                  SPC: true,
                  CPL: true,
                  SGT: true,
                  SFC: true,
                  SGM: true,
                  WO: true,
                }
              },
              10: {
                Name: "CPT",
                Weight: 10,
                BattalionLocked: true,
                CanPromote: {
                    PVT: true,
                    SPC: true,
                    CPL: true,
                    SGT: true,
                    SFC: true,
                    SGM: true,
                    WO: true,
                    LT: true,
                 }
                },
                11: {
                    Name: "MAJ",
                    Weight: 11,
                    BattalionLocked: true,
                    CanPromote: {
                        PVT: true,
                        SPC: true,
                        CPL: true,
                        SGT: true,
                        SFC: true,
                        SGM: true,
                        WO: true,
                        LT: true,
                        CPT: true,
                     }
                    },
                12: {
                    Name: "CMD",
                    Weight: 12,
                    BattalionLocked: true,
                    CanPromote: {
                        PVT: true,
                        SPC: true,
                        CPL: true,
                        SGT: true,
                        SFC: true,
                        SGM: true,
                        WO: true,
                        LT: true,
                        CPT: true,
                        MAJ: true,
                     }
                    },
                13: {
                    Name: "BCMD",
                    Weight: 13,
                    BattalionLocked: true,
                    CanPromote: {
                        PVT: true,
                        SPC: true,
                        CPL: true,
                        SGT: true,
                        SFC: true,
                        SGM: true,
                        WO: true,
                        LT: true,
                        CPT: true,
                        MAJ: true,
                        CMD: true,
                     }
                    },
                14: {
                    Name: "RCMD",
                    Weight: 14,
                    BattalionLocked: true,
                    CanPromote: {
                        PVT: true,
                        SPC: true,
                        CPL: true,
                        SGT: true,
                        SFC: true,
                        SGM: true,
                        WO: true,
                        LT: true,
                        CPT: true,
                        MAJ: true,
                        CMD: true,
                        BCMD: true,
    
                     }
                    },
                15: {
                    Name: "Marshal Commander",
                    Weight: 15,
                    BattalionLocked: true,
                    CanPromote: {
                        PVT: true,
                        SPC: true,
                        CPL: true,
                        SGT: true,
                        SFC: true,
                        SGM: true,
                        WO: true,
                        LT: true,
                        CPT: true,
                        MAJ: true,
                        CMD: true,
                        BCMD: true,
                        RCMD: true,
                     }
                    },
                16: {
                    Name: "Supreme Chancellor",
                    Weight: 15,
                    BattalionLocked: true,
                    CanPromote: {
                        PVT: true,
                        SPC: true,
                        CPL: true,
                        SGT: true,
                        SFC: true,
                        SGM: true,
                        WO: true,
                        LT: true,
                        CPT: true,
                        MAJ: true,
                        CMD: true,
                        BCMD: true,
                        RCMD: true,
                     }
                    },
            },
            // Jedi RANKS
            Jedi: {
                1: {
                    Name: "Youngling",
                    Weight: 1,
                    CanPromote: false
                },
                2: {
                    Name: "Padawan",
                    Weight: 2,
                    CanPromote: false
                },
                3: {
                    Name: "Knight",
                    Weight: 3,
                    CanPromote: false
                },
                4: {
                    Name: "Master",
                    Weight: 4,
                    CanPromote: {
                        Youngling: true,
                        Padawan: true,
                        Knight: true,
                    }
                },
                5: {
                    Name: "Overseer",
                    Weight: 5,
                    CanPromote: {
                        Youngling: true,
                        Padawan: true,
                        Knight: true,
                        Master: true,
                    }
                },
                6: {
                    Name: "Master of the Order",
                    Weight: 6,
                    CanPromote: {
                        Youngling: true,
                        Padawan: true,
                        Knight: true,
                        Master: true,
                        Overseer: true,
                    }
                },
                7: {
                    Name: "Grand Master",
                    Weight: 7,
                    CanPromote: {
                        Youngling: true,
                        Padawan: true,
                        Knight: true,
                        Master: true,
                        Overseer: true,
                        "Master of the Order": true,
                    }
                },
            },
            // NAVAL RANKS 
            Naval: {
                1: {
                    Name: "Cadet",
                    Weight: 1,
                    CanPromote: false
                },
                2: {
                    Name: "Crewman Apprentice",
                    Weight: 2,
                    CanPromote: false
                },
                3: {
                    Name: "Crewman",
                    Weight: 3,
                    CanPromote: false
                },
                4: {
                    Name: "Petty Officer",
                    Weight: 4,
                    CanPromote: false
                },
                5: {
                    Name: "Chief Petty Officer",
                    Weight: 5,
                    CanPromote: false
                },
                6: {
                    Name: "MCPO",
                    Weight: 6,
                    CanPromote: false
                },
                7: {
                    Name: "ENS",
                    Weight: 7,
                    CanPromote: {
                        Cadet: true,
                        "Crewman Apprentice": true,
                        Crewman: true,
                        "Petty Officer": true,
                        "Chief Petty Officer": true,
                        MCPO: true,
                    }
                },
                8: {
                    Name: "LT",
                    Weight: 8,
                    CanPromote: {
                        Cadet: true,
                        "Crewman Apprentice": true,
                        Crewman: true,
                        "Petty Officer": true,
                        "Chief Petty Officer": true,
                        MCPO: true,
                        ENS: true,
                    }
                },
                9: {
                    Name: "CMD",
                    Weight: 9,
                    CanPromote: {
                        Cadet: true,
                        "Crewman Apprentice": true,
                        Crewman: true,
                        "Petty Officer": true,
                        "Chief Petty Officer": true,
                        MCPO: true,
                        ENS: true,
                        LT: true,
                    }
                },
                10: {
                    Name: "Captain",
                    Weight: 10,
                    CanPromote: {
                        Cadet: true,
                        "Crewman Apprentice": true,
                        Crewman: true,
                        "Petty Officer": true,
                        "Chief Petty Officer": true,
                        MCPO: true,
                        ENS: true,
                        LT: true,
                        CMD: true,
                    }
                },
                11: {
                    Name: "Rear Admiral",
                    Weight: 11,
                    CanPromote: {
                        Cadet: true,
                        "Crewman Apprentice": true,
                        Crewman: true,
                        "Petty Officer": true,
                        "Chief Petty Officer": true,
                        MCPO: true,
                        ENS: true,
                        LT: true,
                        CMD: true,
                        Captain: true,
                    }   
                },
                12: {
                    Name: "Admiral",
                    Weight: 12,
                    CanPromote: {
                        Cadet: true,
                        "Crewman Apprentice": true,
                        Crewman: true,
                        "Petty Officer": true,
                        "Chief Petty Officer": true,
                        MCPO: true,
                        ENS: true,
                        LT: true,
                        CMD: true,
                        Captain: true,
                        "Rear Admiral": true,
                    }
                },
            // CIVILIAN RANKS
            Civilian: {
                1: {
                    Name: "Citizen",
                    Weight: 1,
                    CanPromote: false
                },
                2: {
                    Name: "Hunter",
                    Weight: 2,
                    CanPromote: false
                },
                3: {
                    Name: "Guild Evaluator",
                    Weight: 3,
                    CanPromote: false
                },
                4: {
                    Name: "Guild Marshal",
                    Weight: 4,
                    CanPromote: false
                },
                5: {
                    Name: "Guild LT",
                    Weight: 5,
                    CanPromote: false
                },
                6: {
                    Name: "Guild Leader",
                    Weight: 6,
                    CanPromote: false
                },
                7: {
                    Name: "Vice Chair",
                    Weight: 7,
                    CanPromote: false
                },
                8: {
                    Name: "Governer",
                    Weight: 8,
                    CanPromote: false
                },
            }
        }
    }
    }
    }
  const [roster, setRoster] = useState([]);
  let { battalion } = useParams();
  const batt = battalion
  useEffect(() => {
    axios
      .get(`https://api.roster.synergyroleplay.com:42069/api/roster?column=battalionname&filter=${batt}`)
      .then((res) => setRoster(res.data));
  }, [batt]);

    const cloneArray = Object.values(synergy.ranks.Ranks.Clone);
    const sortedCloneArray = cloneArray.sort((a, b) => a.Weight - b.Weight);
    // SORT ROSTER BY RANK
    const sortedRoster = roster.sort((a, b) => {
        const aRank = sortedCloneArray.find((rank) => rank.Name === a.rank);
        const bRank = sortedCloneArray.find((rank) => rank.Name === b.rank);
        return aRank.Weight - bRank.Weight;
    });
    // REVERSE THE ARRAY SO THAT THE HIGHEST RANK IS AT THE TOP
    sortedRoster.reverse();
  return (
    <div className="roster-page">
      <h2>Roster for {battalion}</h2>
      <div className="member-count">Total Members: {sortedRoster.length}</div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Rank</th>
            <th>Subunit</th>
          </tr>
        </thead>
        <tbody>
          {sortedRoster.map((member) => (
            <tr key={member.id}>
              <td>{member.charname}</td>
              <td>{member.rank}</td>
              <td>{member.subunit}</td>
            </tr>
            
          ))}
        </tbody>
      </table>
      
    </div>
  );
};

export default Roster;