import React, { useContext } from 'react';
import { AuthContext } from './AuthProvider';

const Navbar = () => {
    const { user, login, logout } = useContext(AuthContext);

    return (
        <div className="nav">
            <input type="checkbox" id="nav-check" />
            <div className="nav-header">
                <a href="/" className="nav-logo" />
                <div className="nav-title"></div>
            </div>
            <div className="nav-btn">
                <label htmlFor="nav-check">
                    <span></span>
                    <span></span>
                    <span></span>
                </label>
            </div>
            <div className="nav-links">
                <a href="/">Home</a>
                <a href="/">Forums</a>
                {user ? (
                    <>
                        <a href="/rosters">Rosters</a>
                        <button onClick={logout}>Logout</button>
                    </>
                ) : (
                    <button onClick={login}>Login with Steam</button>
                )}
            </div>
        </div>
    );
};

export default Navbar;
    